import React, { useState, useEffect } from "react";
import notesStyles from "./Notes.module.css";
import arrowDown from "./arrow-blue.png";
import useTranslation from "../customHooks/translations";
import documentIcon from "../../images/clipboard 1.svg";
import edit_icon from "../../images/edit-icon.svg";
import { getTimeDifference } from "../../utilities/DateFormate";
export default function PendingForm({ pending_data }) {
  const translation = useTranslation();
  const [pendingshow, setpendingshow] = useState(true);
  let akToken = localStorage.getItem("AkTOKENI");
  const handleShowMore = () => {
    setpendingshow(!pendingshow);
  };
 const DataTranslation=(value, doctor_name, specialty)=>{
  let lang = sessionStorage.getItem("lang_");
  if (value) {
    const styledDoctorName = (
      <span style={{ fontWeight: "600",  fontSize: "15px" }}>
        {doctor_name}
      </span>
    );
    if (lang === "en") {
      const parts = value.split("[doctor’s full name who requested]");

      const output = (
        <span>
          {/* {parts[0]}  */}
          {styledDoctorName}
          {parts[1].replace("[specialty]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "ara") {
      const parts = value.split("[الاسم الكامل للطبيب الذي طلب]");

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[التخصص]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "ger") {
      const parts = value.split(
        "[vollständiger Name des Arztes, der angefragt hat]"
      );

      const output = (
        <span>
          {styledDoctorName}
          {parts[1]}
        </span>
      );

      return output;
    } else if (lang === "ita") {
      const parts = value.split(
        "[nome completo del medico che ha fatto la richiesta]"
      );

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[specialità]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "spa") {
      const parts = value.split(
        "[nombre completo del doctor que lo solicitó]"
      );

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[especialidad]", specialty)}
        </span>
      );

      return output;
    } else if (lang === "fr") {
      const parts = value.split("[nom du Docteur qui fait la demande]");

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[spécialité]", specialty)}
        </span>
      );

      return output;
    } else if(lang==='pol'){
      const parts = value.split("[imię i nazwisko lekarza, który poprosił]");

      const output = (
        <span>
          {styledDoctorName}
          {parts[1].replace("[specjalizacji]", specialty)}
        </span>
      );

      return output;
    }
    
    else {
      return value;
    }
  }
 }
  const PendingContinueForm = (resource_id, type) => {
    if (type === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "general-history" +
          `/${resource_id}?is_doctor_form=null&edit=true&status=3`,
        "_blank"
      );
    } else {
      window.open(
        process.env.REACT_APP_FORM_URL +
          "patient-personal-data" +
          `/${window.btoa(akToken)}` +
          `/${window.btoa(type)}?r=${window.btoa(resource_id)}`,
        "_blank"
      );
    }
  };
  // const continueForm = (resource_id, doctor_id, type) => {
  //   if (type) {
  //     if (type === 5) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "adult-knee-pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 4) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 20) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "pedia-knee-pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 6) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "hip-pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 21) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "foot-ankle-pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 22) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "elbow-pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 23) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "hand-and-wrist-pain" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //     if (type === 33) {
  //       window.open(
  //         process.env.REACT_APP_FORM_URL +
  //           "general-history" +
  //           `/${resource_id}?is_doctor_form=null&edit=true`,
  //         "_blank"
  //       );
  //     }
  //   }
  // };
  return (
    <div>
      <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
        <div className={`notesSec ${notesStyles.notes_sec}`}>
          <div className="whitebox">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className={`${
                    pendingshow ? `${notesStyles.show_more}` : ""
                  } ${notesStyles.addnote_list}`}
                  style={{ border: "none", marginTop: "-8px" }}
                >
                  <img
                    src={arrowDown}
                    onClick={() => handleShowMore()}
                    className={`${notesStyles.note_dropdown}`}
                    alt="icon"
                  />
                </div>
                <h4 className={`${notesStyles.top_left_pf_txt}`}  style={{ fontSize:"20px" }}>
                  {/* {translation.progress} {translation.form} */}
                  {translation.Pending_Forms_mobile}
                </h4>
                <span
                  className={`circle_gap ms-2  ${notesStyles.power_off_text2}`}
                  style={{
                    border: "1px solid #FF7800",
                    // color: "#FF7800",
                    fontWeight: 500,
                    // background: "#f0f0f0",
                    marginTop:"-5px"
                  }}
                >
                  <span >{pending_data.length}</span>
                </span>
              </div>
              {pendingshow && (
                <div
                  //   key={index}
                  style={{ width: "100%", flexDirection: "column" }}
                  className={`notify_item1 d-flex "notify_msg_read"
                                notify_msg_read
                            `}
                  //   onClick={() => handleClick(column)}
                >
                  {pending_data.map((item) => (
                    <div
                      style={{
                        width: "95%",
                        //   backgroundColor:
                        //     column.check === true ? "white" : "",
                      }}
                      className={`notify_item d-flex align-items-center ${notesStyles.notify_msg_unread2}
                `}
                onClick={() =>
                  PendingContinueForm(
                    item.resource_id,
                    item.type
                  )
                }
                    >
      
                      <div className="d-flex align-items-center ms-3 m-lg-0">
                        <div className="me-3">
                          <img
                            src={documentIcon}
                            alt="img"
                            style={{
                              height: "22%",
                              width: "22px",
                              marginLeft: "5px",
                              marginRight: "0px",
                            }}
                          />
                        </div>
                        <div>
                          <div
                            style={{
                              fontWeight: 500,
                              marginLeft: 7,
                              // color: "#3f2783",
                            }}
                            className={`${notesStyles.power_off_text}`}
                          >
                      
                            {DataTranslation(
                              translation.PROMs_questionnaire,
                              item?.more_doctor[0],
                              item.speciality == "Shoulder"
                                  ? translation.shoulder
                                  : item.speciality == "Hip"
                                  ? translation.hip_form
                                  : item.speciality == "Foot And Ankle"
                                  ? translation.Foot_And_Ankle
                                  : item.speciality == "Elbow"
                                  ? translation.Elbow
                                  : item.speciality == "Hand And Wrist"
                                  ? translation.Hand_And_Wrist
                                  : item.speciality == "Adult Knee"
                                  ? translation.adult_knee
                                  : item.speciality == "Paediatric Knee"
                                  ? translation.paediatric_knee
                                  : item.speciality == "Padratic Knee"
                                  ? translation.paediatric_knee
                                  : item.speciality == "Adolescent Knee"
                                  ? translation.adolescent_knee
                                  : item.speciality == "Adolesent Knee"
                                  ? translation.adolescent_knee
                                  : translation.General + " "
                            )}
                          </div>

                          <div className="notification_type">
                            <div className="notify_msg">
                              <div
                                style={{
                                  fontWeight: 500,
                                  marginLeft: 9,
                                  // color: "#3f2783",
                                }}
                                className={`${notesStyles.power_off_text}`}
                              >
                                {getTimeDifference(
                                  item.created_at,
                                  translation
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="notification_type">
                        <div
                          className={`notify_msg ${notesStyles.first_button}`}
                        >
                          <div
                            onClick={() =>
                              PendingContinueForm(
                                item.resource_id,
                                item.type
                              )
                            }
                            style={{
                              cursor: "pointer",
                              textTransform: "none",
                            }}
                          >
                            <img
                              className={`action_icons`}
                              src={edit_icon}
                              alt="Icon"
                              style={{ marginTop:"-5px" }}
                            />
                           <span style={{ marginLeft:"10px" }}>{translation.Answer_the_form}</span> 
                          </div>
                    
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
