import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";
import viewIcon from "../../images/icon-view.svg";
import VideoPlayer from "../custom/video-player/VideoPlayer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "react-bootstrap/Modal";
import useTranslation from "../customHooks/translations";
import { Hours_Minutes_Format } from "../../utilities/DateFormate";
import downloadicon from "../../images/download-icon.svg";
import { s3UrlLinkFile, s3UrlLink } from "../../utilities/S3Url";
import { DocType, WordCutter2 } from "../../utilities/DocumentType";
import axios from "axios";

import { useSelector } from "react-redux";
// let akToken = localStorage.getItem("AkTOKENI");
function DocumentTable({
  data,
  updatePagination,
  DataIdget,
  timezoneD,
  timeFormat,
  resouceId
}) {
  // console.log(data);
  let i = 1;
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [videoLink, setvideoLink] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [profile, setProfile] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: translation.Organization_ID,
        accessor: "msg_type",
      },
      {
        Header: translation.Organization_Name,
        accessor: "message",
      },
    ],
    []
  );

  const { rows } = useTable({
    columns,
    data,
  });

  const WordCutter = (text, maxWords) => {
    const words = text.split(" ");
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(" ");
    return (
      <span>
        {truncatedText}
        {words.length > 10 ? "..." : " "}
      </span>
    );
  };
  const handleView = (
    file_extension,
    file_path,
    resource_type,
    document_name
  ) => {
    setvideoLink(file_path);
    // setMessage(msg);
    setType(file_extension);
    setShow(true);
    setFileName(document_name);
  };
  const handleCloseShow = () => {
    setShow(false);
  };

  const downloadURI = (url, name) => {
    axios
      .get(s3UrlLink(url, name), {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
    }
  }, [login]);

  // const docs = [{ uri: s3UrlLinkFile(filelinkUrl, fileName), fileType: filetype }];
  return (
    <>
      <div
        className="tableContainer bg-white scrollbar_vert"
        id="rrpScrollableDivs"
        style={{ height: "500px" }}
      >
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rrpScrollableDivs"
          loader={""}
        >

          <table role="table" className="custom-tbl mt-3">
            <thead>
              <tr role="row">
                <th colSpan={1} role="columnheader" className="col-orgid">

                  {translation.Document_Name}
                  {/* Document Name */}
                </th>

                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Date_Time}

                  {/* type */}
                </th>
                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Type}
                </th>

                {/* <th colSpan={1} role="columnheader" className="col-status"> */}
                {/* {translation.Type} */}
                {/* Access Type */}
                {/* </th> */}

                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Action}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr role="row"
                  style={{
                    backgroundColor:
                      row.resource_id == resouceId ? "#4027831e" : "inherit",
                  }}
                >
                  {/* <td role="cell" className="col-orgid" data-bs-toggle="tooltip"
                      title={row.document_name}>
                         {row.document_name.substring(0, 5)}
                 
                    {row.document_name}
                  </td> */}
                  <td role="cell" className="col-orgid document-name" data-bs-toggle="tooltip" title={row.document_name}>
                    {row.document_name.length > 25 ? `${row.document_name.substring(0, 25)}...` : row.document_name}
                  </td>
                  <td className="col">
                    {/* {UserDateFormat(row.date , profile.dateFormat)}{" "} */}
                    {row.date} {" "}
                    {Hours_Minutes_Format(
                      row.time,
                      timeFormat === "1" ? true : false,
                      timezoneD
                    )}
                  </td>
                  <td className="col">
                    {DocType(row.file_extension, row.file_type, translation)}
                  </td>
                  {/* <td className="col">

                  </td> */}

                  <td role="cell" className="col-orgname">
                  <div className="icon-container">
                      {row.file_extension === "hevc" ||
                        row.file_extension == "mov" ||
                        row.file_extension == "doc" ||
                        row.file_extension == "xlsx" ? (
                        ""
                      ) : (
                        <img
                          data-bs-toggle="tooltip"
                          // data-bs-placement="top"
                          title={translation.View}
                          className={`action_icons c_pointer `}
                          src={viewIcon}
                          alt="Icon"
                          onClick={() =>
                            handleView(
                              row.file_extension,
                              row.file_path,
                              row.resource_type,
                              row.file_name
                            )
                          }
                        />
                      )}

                      <img
                        src={downloadicon}
                        onClick={() => {
                          downloadURI(row.file_path, row.file_name);
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={translation.download}
                        className={`action_icons c_pointer ms-3 me-3`}
                      ></img>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <Modal
        show={show}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{translation.View}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {/* {videoLink} */}
          {/* <div className="msg-box">
            <span className=""> {message}</span>
          </div> */}
          {type === "mp4" ? (
            <VideoPlayer url={s3UrlLinkFile(videoLink, fileName)} />
          ) : (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              prefetchMethod="GET"
              documents={[
                { uri: s3UrlLinkFile(videoLink, fileName), fileType: type },
              ]}
              // requestHeaders={headers}
              config={{
                // loadingRenderer: {
                //   overrideComponent: MyLoadingRenderer,
                // },
                header: {
                  disableHeader: false,
                  disableFileName: true,
                  retainURLParams: true,
                },
              }}
            />
          )}
        </Modal.Body>

      </Modal>
    </>
  );
}

export default DocumentTable;
