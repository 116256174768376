import React, {useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../images/back.png";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import InputField from "../custom/inputfield/InputField";
import { Validators } from "../../utilities/Validator";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import useTranslation from "../customHooks/translations";
import { loginData } from "../../redux/actions/index";
import GoogleAddress from "../custom/google-api-address/GoogleAddress";

function MyProfileStep2() {
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  let axiosInstance = useAxios();
  const [kinName, setKinName] = useState("");
  const [kinPhone, setKinPhone] = useState("");
  const [kinPhoneErr, setKinPhoneErr] = useState(false);
  const [kinRelationship, setKinRelationship] = useState("");
  const [gpName, setGpName] = useState("");
  const [gpPracticeName, setGpPracticeName] = useState("");
  const [gpAddress, setGpAddress] = useState("");
  const [profile, setProfile] = useState({});
  // const [profileInfo, setProfileInfo] = useState({});
  const [addressError, setaddressError] = useState(false);
  const navigate = useNavigate();
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  useEffect(() => {
    if (login) {
      setName(login.patient.firstname);
    }
  }, []);


  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      // setProfileInfo(login.patientInfo);
      setKinName(login.patientInfo.kin_name);
      setKinPhone(login.patientInfo.kin_phone);
      setKinRelationship(login.patientInfo.kin_relationship);
      setGpName(login.patientInfo.gp_name);
      setGpPracticeName(login.patientInfo.gp_practice_name);
      setGpAddress(login.patientInfo.gp_address);
    }
  }, [login]);
  const kinNameRef = useRef();
  const kinPhoneRef = useRef();
  const kinRelationshipRef = useRef();
  const gpNameRef = useRef();
  const gpPracticeNameRef = useRef();
  // const gpAddressRef = useRef();


  const handleKinName = (e) => {
    setKinName(e);
  };
  const handleKinPhone = (e, err) => {
    setKinPhone(e);
    setKinPhoneErr(err);
  };
  const handleKinRelationship = (e) => {
    setKinRelationship(e);
  };
  const handleGpName = (e) => {
    setGpName(e);
  };
  const handleGpPracticeName = (e) => {
    setGpPracticeName(e);
  };
  const handleGpAddress = (e) => {
    setGpAddress(e);
    setaddressError(false);
  };
  const getData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${profile.ak_id}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          sessionStorage.setItem("lang_", result.data.data[0].patient.user_language);
          sessionStorage.setItem("isConsent", result.data.data[0].patientInfo.isConsent)
        }
      }
    } catch (err) {
      
      console.log(err.Messages);

    } finally {
      console.log("api response finally");
    }
  };
  
  const continueRedirect = (e) => {
    e.preventDefault();

    if (!kinName) {
      kinNameRef.current.errUpdate();
    }
    if (!kinPhone) {
      kinPhoneRef.current.errUpdate();
    }
    if (!kinRelationship) {
      kinRelationshipRef.current.errUpdate();
    }
    if (!gpName) {
     
      gpNameRef.current.errUpdate();
    }
    if (!gpAddress) {
      setaddressError(true);
      
    }if( gpAddress==="" || gpAddress===undefined){
    return setaddressError(true);
    }
    if (!gpPracticeName) {
      gpPracticeNameRef.current.errUpdate();
    }

    if (
      !kinName ||
      !kinPhone ||
      !kinRelationship ||
      !gpName ||
      !gpPracticeName ||
      kinPhoneErr ||
      addressError
    ) {
    } else {
      const json = JSON.stringify({
        kin_name: kinName,
        kin_phone: kinPhone,
        kin_relationship: kinRelationship,
        gp_name: gpName,
        gp_practice_name: gpPracticeName,
        gp_address: gpAddress,
      });
      axiosInstance.current
        .post(
          `extapp/patients/create_Profile?ak_id=${profile.ak_id}&type=2`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          
          getData();
          navigate("/my-profile3");
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="myPprofile_screen mb_100">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4 className="ph_title">{translation.myProfile}</h4>
            </div>
          </div>

          <div className="panel">
            <div className="panel-body p_25">
              <div className="row">
                <div className="col-sm-10 col-md-10 mb_20">
                  <InputField
                    label={translation.Emergency_Contact}
                    value={kinName}
                    ref={kinNameRef}
                    type="text"
                    placeholder={translation.full_name}
                    onChange={handleKinName}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Phone}
                    value={kinPhone}
                    ref={kinPhoneRef}
                    type="text"
                    placeholder="_ _ _ _ _ _ _ _ _ _"
                    onChange={handleKinPhone}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                      {
                        check: Validators.maxmobile,
                        message: translation.maximum_10_Numbers_are_allowed,
                      },
                      // {
                      //   check: Validators.minmobile,
                      //   message: "Maximum 10 Numbers are allowed",
                      // },
                      {
                        check: Validators.number,
                        message: translation.Only_I,
                      },
                    ]}
                    styleClass=" br_10"
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Relationship}
                    value={kinRelationship}
                    ref={kinRelationshipRef}
                    type="text"
                    placeholder={translation.Relationship}
                    onChange={handleKinRelationship}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                    styleClass=" br_10"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Regular_GP_Name}
                    value={gpName}
                    ref={gpNameRef}
                    type="text"
                    placeholder={translation.full_name}
                    onChange={handleGpName}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Practice_Name}
                    value={gpPracticeName}
                    ref={gpPracticeNameRef}
                    type="text"
                    placeholder={translation.full_name}
                    onChange={handleGpPracticeName}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10 col-md-10 mb_20">
                  {/* <InputField
                    label={translation.Address}
                    value={gpAddress}
                    ref={gpAddressRef}
                    type="text"
                    placeholder="1234 john st Subrub, 4000"
                    onChange={handleGpAddress}
                    validators={[
                      {
                        check: Validators.required,
                        message: translation.This_field_is_required,
                      },
                    ]}
                    formGroupClass=""
                    styleClass="br_10 "
                  /> */}
                  <GoogleAddress
                    label={translation.Address}
                    onAddressChange={handleGpAddress}
                    valid={addressError}
                    value={gpAddress}
                  ></GoogleAddress>
                </div>
              </div>
            </div>
           
            {/* <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                <Link to="/my-profile" className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div> */}
            <div className="d-flex align-items-center justify-content-between gap-3 button_box">
              <div className="">
                <Link to="/my-profile" className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              
              <div className="">
                <button
                   className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyProfileStep2;
